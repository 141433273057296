// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-patient-tsx": () => import("./../../../src/pages/new-patient.tsx" /* webpackChunkName: "component---src-pages-new-patient-tsx" */),
  "component---src-pages-prosthodontist-tsx": () => import("./../../../src/pages/prosthodontist.tsx" /* webpackChunkName: "component---src-pages-prosthodontist-tsx" */),
  "component---src-pages-referral-tsx": () => import("./../../../src/pages/referral.tsx" /* webpackChunkName: "component---src-pages-referral-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-service-detail-tsx": () => import("./../../../src/templates/serviceDetail.tsx" /* webpackChunkName: "component---src-templates-service-detail-tsx" */)
}

